"use client";

import NextLink from "next/link";
import React from "react";
import { useIsWebview } from "../ApplicationState/hooks";
import { NavigateButton } from "../NavigateButton";
import styles from "./styles.module.scss";
import type { ItemSnow } from "./types";

export const SnowLinkItem: React.FC<ItemSnow> = ({
    name,
    id,
    link,
    asPath,
    country,
    distance,
}) => {
    const isWebview = useIsWebview();

    return (
        <div className={styles.locationInner} key={id}>
            {isWebview ? (
                <NavigateButton
                    url={asPath}
                    className={styles.locationInnerButton}
                >
                    <SubTree
                        country={country}
                        distance={distance}
                        name={name}
                    />
                </NavigateButton>
            ) : (
                <NextLink
                    href={link}
                    as={asPath}
                    data-label={`snowLink_textLink_${name}`}
                    aria-label={`Wetter im Skigebiet ${name}`}
                    className={styles.locationA}
                >
                    <SubTree
                        country={country}
                        distance={distance}
                        name={name}
                    />
                </NextLink>
            )}
        </div>
    );
};

const SubTree: React.FC<Pick<ItemSnow, "distance" | "country" | "name">> = ({
    distance,
    country,
    name,
}) => {
    return (
        <div className={styles.locationCnt}>
            <div className={styles.colText}>{country}</div>
            <div className={styles.colTextBold}>{name}</div>
            {distance !== null ? (
                <div className={styles.colDistance}>{distance}</div>
            ) : null}
            <span className="icon-dropdown" />
        </div>
    );
};
