"use client";

import NextScript from "next/script";
import React, { useEffect, useState } from "react";
import { getWindow } from "../../helpers/getWindow";
import { useIsPageChange } from "../../hooks/useIsPageChange";
import { useConsent, useIsPay } from "../Consent/hooks";
import { sendError } from "../ErrorBoundary/helpers";

type Props = {
    cp: string;
};

export const Oewa: React.FC<Props> = ({ cp }) => {
    const consent = useConsent();
    const isPay = useIsPay();
    const [oewaBundleLoaded, setOewaBundleLoaded] = useState(false);
    const [oewaScriptLoaded, setOewaScriptLoaded] = useState(false);
    const window = getWindow();
    const oewaHost = "data-ab6e448dac.wetter.com";
    const isPageChange = useIsPageChange();

    useEffect(() => {
        if (isPageChange) {
            if (typeof window?.IOMm === "function" && cp) {
                window.IOMm("pageview", { cp });
            }
        }
    }, [window, window?.IOMm, cp, isPageChange]);

    useEffect(() => {
        const node = window?.document.createElement("link");
        if (!node) {
            return;
        }
        node.href = `https://${oewaHost}/iomm/latest/manager/base/es6/bundle.js`;
        node.rel = "preload";
        node.as = "script";
        node.crossOrigin = "";
        node.id = "IOMmBundle";

        window?.document.head.appendChild(node);
        // give script some time to load
        window?.setTimeout(() => {
            setOewaBundleLoaded(true);
        }, 25);
    }, [window]);

    try {
        if (!consent || isPay || !cp) {
            return null;
        }

        return oewaBundleLoaded ? (
            <>
                <NextScript
                    type="text/javascript"
                    id="oewa"
                    src={`https://${oewaHost}/iomm/latest/bootstrap/loader.js`}
                    strategy="lazyOnload"
                    onLoad={(): void => {
                        setOewaScriptLoaded(true);
                    }}
                />
                {oewaScriptLoaded && (
                    <NextScript
                        id="oewa-config"
                        strategy="lazyOnload"
                        dangerouslySetInnerHTML={{
                            __html: `
                            if (window.iom) {
                                IOMm('configure', { st: 'at_w_comwetter', dn: '${oewaHost}', cn: 'at', mh: 5 });
                                IOMm('pageview', { cp: "${cp}" });
                            }
                        `,
                        }}
                    />
                )}
            </>
        ) : null;
    } catch (e) {
        sendError(e);
        return null;
    }
};
