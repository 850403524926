"use client";
import React, { useState } from "react";
import { cn } from "../../helpers/classNames";
import { pushDataLayer } from "../../helpers/dataLayer";
import { Button } from "../Button";
import styles from "./styles.module.scss";

type Props = {
    contentContainerRef: string;
};

export const ToggleButton: React.FC<Props> = ({ contentContainerRef }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = (): void => {
        setIsOpen(!isOpen);

        document
            .getElementsByClassName(contentContainerRef)[0]
            ?.classList.toggle(styles.expanded ?? "");

        if (isOpen && styles.scrollAnchor) {
            document
                .getElementsByClassName(styles.scrollAnchor)[0]
                ?.scrollIntoView({
                    behavior: "smooth",
                    inline: "center",
                    block: "nearest",
                });
        }

        pushDataLayer({
            type: "event",
            event: "clicktrack",
            eventCategory: "function",
            eventAction: "click",
            eventLabel: "Mehr_News",
        });
    };

    return (
        <Button
            className={styles.toggleButton}
            variant="secondary"
            onClick={toggle}
            fullWidth={true}
        >
            <>
                {`${isOpen ? "weniger" : "weitere"} Topthemen`}
                <span
                    className={cn(
                        styles.buttonIconWrapper,
                        isOpen && styles.arrowUp
                    )}
                >
                    <span className="icon-dropdown" />
                </span>
            </>
        </Button>
    );
};
