"use client";

import type React from "react";
import globalStyles from "../../app/common/styles.module.scss";
import { sendEventToNativeApp } from "../../bridge/helpers";
import { cn } from "../../helpers/classNames";
import { getWindow } from "../../helpers/getWindow";

type Props = {
    children: React.ReactNode;
    url: string;
    className?: string;
};

export const NavigateButton: React.FC<Props> = ({
    children,
    url,
    className,
}) => {
    const window = getWindow();

    return (
        <button
            className={cn(globalStyles.unstyled, className)}
            onClick={(): void =>
                sendEventToNativeApp({
                    type: "navigate",
                    url,
                    prevUrl: window
                        ? window.location.pathname +
                          window.location.search +
                          window.location.hash
                        : "",
                })
            }
        >
            {children}
        </button>
    );
};
